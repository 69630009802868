import React, { useState } from "react";
import { BrowserRouter, Link, Route, Switch, Redirect } from "react-router-dom";
import "./App.css";

import Home from "../Home/Home";
import Form from "../Form/Form";

import NotFound from "../NotFound/NotFound";

import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Slide from "@material-ui/core/Slide";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import MenuIcon3 from "./../../assets/iconmonstr-menu-5-240.png";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import { animateScroll as scroll } from "react-scroll";

// const openInNewTab = (url) => {
//   const newWindow = window.open(url, "_blank", "noopener,noreferrer");
//   if (newWindow) newWindow.opener = null;
// };

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    // threshold: 0,
    threshold: 330,

    target: window ? window() : undefined,
  });

  return React.cloneElement(
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>,
    {
      //   elevation: trigger2 ? 4 : 0,
      elevation: 0,
    }
  );
}

const useStyles = makeStyles((theme) => ({
  // appBar: {
  //   borderBottom: `1px solid ${theme.palette.divider}`,
  // },
  // toolbar: {
  //   flexWrap: 'wrap',
  // },
  // toolbarTitle: {
  //   flexGrow: 1,
  // },
  // link: {
  //   margin: theme.spacing(1, 1.5),
  // },
  // heroContent: {
  //   padding: theme.spacing(8, 0, 6),
  // },
  // cardHeader: {
  //   backgroundColor:
  //     theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  // },
  // cardPricing: {
  //   display: 'flex',
  //   justifyContent: 'center',
  //   alignItems: 'baseline',
  //   marginBottom: theme.spacing(2),
  // },

  toolbar: {
    // zIndex: "9999",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbarTitle: {
    fontFamily: "'Josefin Sans', sans-serif",
    // fontFamily:'Roboto',
    flex: 1,
    marginTop: "4px",
    marginBottom: "7px",
    // fontWeight: 'bold',
    fontSize: "2.5em",
  },
  toolbarSubtitle: {
    // fontFamily:'Merriweather',
    // color:'#FF472A'
    // FF872A
  },
  toolbarTitleText: {
    // marginRight:'auto'

    // display:'none'
    // marginTop:'-34px',
    // marginBottom:'10px'
    // color: titleTextColor
    color: (props) => props.backgroundColor,
  },
  toolbarSecondary: {
    justifyContent: "space-between",
    overflowX: "auto",
  },
  toolbarLink: {
    fontFamily: "'Josefin Sans', sans-serif",
    // fontFamily: "operetta-8",

    // fontFamily: "'Archivo', sans-serif",

    padding: theme.spacing(1),
    flexShrink: 0,
    // textTransform: 'uppercase',
    fontSize: "1.3em",
    color: (props) => props.backgroundColor,
    textDecoration: "none",

    // "&:focus, &:hover, &:visited, &:link, &:active" :{
    //     textDecoration: 'none',
    // }
    " &:hover": {
      textDecoration: "none",
      // fontSize:'1.4rem',
      fontWeight: "bold",
    },
    " &:active": {
      // textDecoration: 'none',
      // fontSize:'1.4rem',
      // fontWeight:'bold',
    },
  },
  logo: {
    height: "5em",
    // marginTop:'-2px',

    marginBottom: "-3px",
    // marginRight:'auto'
  },
  tabs: {
    marginBottom: "-10px",
    marginLeft: "-10px",
    // textTransform: 'uppercase'
    // marginRight:'100px'
    // paddingRight:'100px'
  },
  toolbarMargin: {
    ...theme.mixins.toolbar,
  },
  // appbarcontainer:{
  //   // backgroundColor: "white"
  // }
  button: {
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    background: (props) => props.buttonColor,
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 48,
    padding: "0 30px",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  },
  burgerButton: {
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    // background: props => props.buttonColor,
    // borderRadius: 3,
    // border: 0,
    color: (props) => props.backgroundColor,
    // color:'white'
    // height: 48,
    // padding: '0 30px',
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  },
}));

// const scrollToRef = function (ref, offset) {
//   scroll.scrollTo(ref.current.offsetTop - offset);
// };

function App() {
  const [aboutValue, setAboutValue] = useState(" Default Value ");
  const [mediaValue, setMediaValue] = useState(" Default Value ");

  const theme = useTheme();

  const classes = useStyles();

  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  // const matches2 = useMediaQuery(theme.breakpoints.down("sm"));

  const [openDrawer, setOpenDrawer] = useState(false);
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

  // const blogRef = useRef(null);
  // const contactRef = useRef(null);

  const sections = [
    { title: "Home", url: "/home" },
    // { title: "About" },
    // { title: "Media" },
    // { title: "Home", url: "/home" },

    // { title: 'Resources', url: '/resources', Component: Resources },
    // { title: 'Contribute', url: '/contribute', Component: About },
    // { title: 'About', url: '/about', Component: About },
    // { title: 'Learn', url: '/contribute', Component: About },
    // {
    //   title: "Contact Us",
    //   url: "/questionnaire",
    // },
    // {
    //   title: "Benefits",
    //   url: "/benefits",
    // },
    // {
    //   title: "Contact",
    //   url: "/contact",
    // },

    // { title: 'Contactos', url: '#' },
    // { title: 'Politics', url: '#' },
    // { title: 'Opinion', url: '#' },
    // { title: 'Science', url: '#' },
    // { title: 'Health', url: '#' },
    // { title: 'Style', url: '#' },
    // { title: 'Travel', url: '#' },
  ];

  // const executeScroll_blog = () => scrollToRef(blogRef, 20);
  // const executeScroll_contact = () => scrollToRef(contactRef, 20);

  const executeScroll_about = () => {
    setTimeout(function () {
      scroll.scrollTo(aboutValue);
    }, 500);
  };
  const executeScroll_media = () => {
    setTimeout(function () {
      scroll.scrollTo(mediaValue);
    }, 500);
  };

  const drawer = (
    <React.Fragment>
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
      >
        {/* Example Drawer */}

        {/* <div className={classes.toolbar} /> */}
        <br></br>
        <br></br>
        <br></br>

        <Divider />
        <List>
          <ListItem
            button
            key={1}
            onClick={() => {
              setOpenDrawer(false);
            }}
            // component={Link} to='/media'
          >
            {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
            {/* <ListItemText primary={'Home'} /> */}
            <Link
              // color="inherit"
              // color="white"
              noWrap
              key={"Home"}
              variant="body2"
              // href={"/"}
              to={"/home"}
              className={classes.toolbarLink}
              style={{ color: "black" }}
            >
              {"Home"}
            </Link>
          </ListItem>
          <ListItem
            button
            key={1}
            onClick={() => {
              setOpenDrawer(false);
            }}
            // component={Link} to='/media'
          >
            {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
            {/* <ListItemText primary={'Home'} /> */}
            <Link
              // color="inherit"
              // color="white"
              noWrap
              key={"About"}
              variant="body2"
              // href={"#"}
              to={"/home"}
              onClick={executeScroll_about}
              className={classes.toolbarLink}
              style={{ color: "black" }}
            >
              {"About"}
            </Link>
          </ListItem>
          <ListItem
            button
            key={1}
            onClick={() => {
              setOpenDrawer(false);
            }}
            // component={Link} to='/media'
          >
            {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
            {/* <ListItemText primary={'Home'} /> */}
            <Link
              // color="inherit"
              // color="white"
              noWrap
              key={"Media"}
              variant="body2"
              // href={"#"}
              to={"/home"}
              onClick={executeScroll_media}
              className={classes.toolbarLink}
              style={{ color: "black" }}
            >
              {"Media"}
            </Link>
          </ListItem>

          <ListItem
            button
            key={2}
            onClick={() => {
              setOpenDrawer(false);
            }}
          >
            {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
            {/* <ListItemText primary={'Media'} /> */}
            <Link
              // color="inherit"
              // color="black"
              noWrap
              key={"Booking"}
              variant="body2"
              // href={"/booking"}
              to={"/booking"}
              className={classes.toolbarLink}
              style={{ color: "black" }}
            >
              {"Booking"}
            </Link>
          </ListItem>
          {/*  */}
          {/* {['Home', 'Media', 'Current Projects', 'Contact'].map((text, index) => (
        <ListItem button key={text}>
          <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
          <ListItemText primary={text} />
        </ListItem>
      ))} */}
        </List>
        {/* <Divider /> */}
        {/* <List>
      {['All mail', 'Trash', 'Spam'].map((text, index) => (
        <ListItem button key={text}>
          <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
          <ListItemText primary={text} />
        </ListItem>
      ))}
    </List> */}
      </SwipeableDrawer>

      <img
        alt="drawer button icon"
        src={MenuIcon3}
        onClick={() => setOpenDrawer(!openDrawer)}
        // onClick={() =>
        //   openInNewTab("https://www.facebook.com/magicbysebastian")
        // }
        // className={classes.LGgrey}
        // className="section-images-bio"
        // className="section-images-long"
        style={{
          width: "6vh",
          // paddingRight: "15px",
          paddingTop: "15px",
          cursor: "pointer",
        }}
      ></img>
    </React.Fragment>
  );

  const tabs = (
    <React.Fragment>
      <div
        className={classes.tabs}
        style={
          {
            // zIndex: "9999",
            // marginBottom:'-90px'
          }
        }
      >
        {sections.map((section) => (
          <Link
            // color="inherit"
            // color="white"
            noWrap
            key={section.title}
            variant="body2"
            // href={section.url}
            to={section.url}
            className={classes.toolbarLink}
            style={{ color: "white" }}
            activeStyle={{ color: "red" }}
          >
            {section.title}
          </Link>
        ))}
        <Link
          // color="inherit"
          // color="white"
          noWrap
          // key={section.title}
          variant="body2"
          // href="contact"
          // href={"/booking"}
          to={"/home"}
          // component={RouterLink}
          onClick={executeScroll_about}
          className={classes.toolbarLink}
          style={{
            color: "white",
            // color:'#CA0C0C',
            // color:'#FD191B',

            // color:'white',

            // fontFamily: "'Montserrat', sans-serif",
            // fontSize: '0.8rem',
            // textShadow: '1px 1px 2px'

            // textShadow: '1px 1px 5px #F50A0A'
          }}
        >
          About{/* {section.title} */}
        </Link>
        <Link
          // color="inherit"
          // color="white"
          noWrap
          // key={section.title}
          variant="body2"
          // href="contact"
          // href={"/booking"}
          // to={"/booking"}
          to={"/home"}
          // component={RouterLink}
          onClick={executeScroll_media}
          className={classes.toolbarLink}
          style={{
            color: "white",
            // color:'#CA0C0C',
            // color:'#FD191B',

            // color:'white',

            // fontFamily: "'Montserrat', sans-serif",
            // fontSize: '0.8rem',
            // textShadow: '1px 1px 2px'

            // textShadow: '1px 1px 5px #F50A0A'
          }}
        >
          Media
          {/* {section.title} */}
        </Link>
        <Link
          // color="inherit"
          // color="white"
          noWrap
          // key={section.title}
          variant="body2"
          // href="contact"
          // href={"/booking"}
          to={"/booking"}
          // component={RouterLink}
          className={classes.toolbarLink}
          style={{
            color: "white",
            // color:'#CA0C0C',
            // color:'#FD191B',

            // color:'white',

            // fontFamily: "'Montserrat', sans-serif",
            // fontSize: '0.8rem',
            // textShadow: '1px 1px 2px'

            // textShadow: '1px 1px 5px #F50A0A'
          }}
        >
          Booking
          {/* {section.title} */}
        </Link>

        {/* <Button 
      className={classes.button}
      variant="contained" 
      // color={ props.buttonColor}
      >test</Button> */}
      </div>
    </React.Fragment>
  );

  return (
    <div className="wrapper">
      {/* <h1>Marine Mammals</h1> */}
      <BrowserRouter>
        {/* <nav style={{ position: "absolute", zIndex: 1110 }}>
          <ul>
            <li>
              <Link to="/home">Home</Link>
            </li>
            <li>
              <Link to="/ab">About</Link>
            </li>
            <li>
              <Link to="/med">Media</Link>
            </li>
            <li>
              <Link to="/booking">Booking</Link>
            </li>
          </ul>
        </nav> */}
        <HideOnScroll
        // {...props}
        >
          {/* <HideOnScroll {...props}         
       
> */}
          <AppBar color={"transparent"}>
            <Toolbar
              className={classes.toolbar}
              style={{
                // borderColor: 'rgba(8, 233, 255, 0.01)'
                border: "none",
                // position: "relative",
                // zIndex: 1110,
              }}
            >
              {/* <Button size="small">Subscribe</Button> */}
              {/* <img alt="website logo" src={logo2} className={classes.logo}>
        </img> */}

              <div
                style={{
                  marginRight: "auto",
                  // overflow: "hidden",
                  // textOverflow: "ellipsis",
                  // width: '11rem'
                }}
              >
                <Typography
                  component="h2"
                  variant="h5"
                  color="inherit"
                  // align="center"
                  noWrap
                  className={classes.toolbarTitle}
                >
                  {/* <span className= {classes.toolbarTitleText}>
{title}
</span> */}

                  {/* <p className={classes.toolbarSubtitle} style={{
                  marginTop: '5px',
                  fontSize: '14px',
                  textTransform: 'uppercase',
                  paddingTop: '0px'

                }}
                >{'Planta Empacadora Y Exportadora '}
                  <br></br>
                  {'De Frutos y Vegetales Frescos'}
                </p> */}

                  {/* </span> */}
                </Typography>
              </div>
              {/* <IconButton>
          <SearchIcon />
        </IconButton> */}
              {/* <Button variant="outlined" size="small">
          Sign up
        </Button> */}

              {matches ? drawer : tabs}
              {/* {matches ? null : tabs} */}

              {/* {tabs} */}
              {/* <div className={classes.tabs} style={{
  // marginBottom:'-90px'
}}>
        {sections.map(section => (
          <Link
            color="inherit"
            noWrap
            key={section.title}
            variant="body2"
            href={section.url}
            className={classes.toolbarLink}
          >
            {section.title}
          </Link>
        ))}

</div> */}
            </Toolbar>
          </AppBar>
        </HideOnScroll>

        <Switch>
          <Route path="/" exact>
            <Redirect to="/home" />
          </Route>
          <Route path="/home">
            <Home setAboutValue={setAboutValue} setMediaValue={setMediaValue} />
          </Route>
          <Route path="/booking">
            <Form />
          </Route>

          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
