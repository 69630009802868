import React, { useEffect, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import logo from "./../../assets/Hamsa White Transparent.png";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";

// import AppBar from "@material-ui/core/AppBar";
// import Toolbar from "@material-ui/core/Toolbar";
// import Typography from "@material-ui/core/Typography";

// import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

import closeUpInteractiveImage from "./../../assets/images/Close Up Interactive Magic.jpg";
// import craftedMagicImage from "./../../assets/images/_Crafted Magic Experience_.jpg";
import officialBioImage from "./../../assets/images/_Official Bio_.jpeg";
import groupMagicShowImage from "./../../assets/images/Group Show.png";

import LGgrey from "./../../assets/logo-top/LG grey.png";
import lockheedGrey from "./../../assets/logo-top/lockheedgrey.png";
import OrlandoMagicGrey from "./../../assets/logo-top/Orlando Magic.png";
import SunTrustGrey from "./../../assets/logo-top/SunTrust Grey.png";
import WellsFargoGrey from "./../../assets/logo-top/Wells Fargo Grey.png";

import Iframe from "react-iframe";

import FacebookSVG from "./../../assets/facebook-3-xxl.png";
import InstagramSVG from "./../../assets/instagram-xxl.png";

// import ScrollAnimation from "react-animate-on-scroll";

// import "animate.css/animate.min.css";

import Fade from "react-reveal/Fade";
const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  groupImage: {
    order: 2,
    [theme.breakpoints.up("sm")]: {
      order: 1,
    },
  },
  groupText: {
    order: 1,
    [theme.breakpoints.up("sm")]: {
      order: 2,
    },
  },
  // appBar: {
  //   borderBottom: `1px solid ${theme.palette.divider}`,
  // },
  // toolbar: {
  //   flexWrap: 'wrap',
  // },
  // toolbarTitle: {
  //   flexGrow: 1,
  // },
  // link: {
  //   margin: theme.spacing(1, 1.5),
  // },
  // heroContent: {
  //   padding: theme.spacing(8, 0, 6),
  // },
  // cardHeader: {
  //   backgroundColor:
  //     theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  // },
  // cardPricing: {
  //   display: 'flex',
  //   justifyContent: 'center',
  //   alignItems: 'baseline',
  //   marginBottom: theme.spacing(2),
  // },
  LGgrey: {
    marginBottom: "18px",

    [theme.breakpoints.up("md")]: {
      // paddingLeft: "20px",
      paddingRight: "20px",
      marginLeft: "-35px",
    },
  },
  lockheedGrey: {
    paddingTop: "10px",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  },
  OrlandoMagicGrey: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
      marginLeft: "-20px",
      marginRight: "20px",
      marginBottom: "18px",
    },
  },
  SunTrustGrey: {
    [theme.breakpoints.up("md")]: { marginRight: "15px" },
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  WellsFargoGrey: {
    marginBottom: "20px",
    // marginTop: "-20px",
    [theme.breakpoints.up("md")]: { paddingLeft: "20px" },
  },

  footer: {
    //   borderTop: `1px solid ${theme.palette.divider}`,
    //   marginTop: theme.spacing(8),
    //   paddingTop: theme.spacing(3),
    //   paddingBottom: theme.spacing(3),
    //   [theme.breakpoints.up('sm')]: {
    //     paddingTop: theme.spacing(6),
    //     paddingBottom: theme.spacing(6),
    //   },
  },
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbarTitle: {
    fontFamily: "'Josefin Sans', sans-serif",
    // fontFamily:'Roboto',
    flex: 1,
    marginTop: "4px",
    marginBottom: "7px",
    // fontWeight: 'bold',
    fontSize: "2.5em",
  },
  toolbarSubtitle: {
    // fontFamily:'Merriweather',
    // color:'#FF472A'
    // FF872A
  },
  toolbarTitleText: {
    // marginRight:'auto'

    // display:'none'
    // marginTop:'-34px',
    // marginBottom:'10px'
    // color: titleTextColor
    color: (props) => props.backgroundColor,
  },
  toolbarSecondary: {
    justifyContent: "space-between",
    overflowX: "auto",
  },
  toolbarLink: {
    fontFamily: "'Josefin Sans', sans-serif",
    // fontFamily: "operetta-8",

    // fontFamily: "'Archivo', sans-serif",

    padding: theme.spacing(1),
    flexShrink: 0,
    // textTransform: 'uppercase',
    fontSize: "1.3em",
    color: (props) => props.backgroundColor,
    textDecoration: "none",

    // "&:focus, &:hover, &:visited, &:link, &:active" :{
    //     textDecoration: 'none',
    // }
    " &:hover": {
      textDecoration: "none",
      // fontSize:'1.4rem',
      fontWeight: "bold",
    },
    " &:active": {
      // textDecoration: 'none',
      // fontSize:'1.4rem',
      // fontWeight:'bold',
    },
  },
  logo: {
    height: "5em",
    // marginTop:'-2px',

    marginBottom: "-3px",
    // marginRight:'auto'
  },
  tabs: {
    marginBottom: "-10px",
    marginLeft: "-10px",
    // textTransform: 'uppercase'
    // marginRight:'100px'
    // paddingRight:'100px'
  },
  toolbarMargin: {
    ...theme.mixins.toolbar,
  },
  // appbarcontainer:{
  //   // backgroundColor: "white"
  // }
  button: {
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    background: (props) => props.buttonColor,
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 48,
    padding: "0 30px",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  },
  burgerButton: {
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    // background: props => props.buttonColor,
    // borderRadius: 3,
    // border: 0,
    color: (props) => props.backgroundColor,
    // color:'white'
    // height: 48,
    // padding: '0 30px',
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  },
}));

// const scrollToRef = function (ref, offset) {
//   scroll.scrollTo(ref.current.offsetTop - offset);
// };

export default function Home(props) {
  const classes = useStyles();
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const matches2 = useMediaQuery(theme.breakpoints.down("sm"));

  // const [openDrawer, setOpenDrawer] = useState(false);
  // const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

  const aboutRef = useRef(null);
  const mediaRef = useRef(null);

  // const [navAboutPosition, setAboutRefPosition] = useState(0);

  useEffect(() => {
    // console.log(aboutRef.current.offsetTop);
    // console.log(mediaRef.current.offsetTop);
    props.setAboutValue(aboutRef.current.offsetTop);
    props.setMediaValue(mediaRef.current.offsetTop);
  }, [aboutRef, props]);

  return (
    <div>
      <div className="App" style={{ maxWidth: "100vw", overflow: "hidden" }}>
        <div
          style={{
            // backgroundColor:'#D0D0D0'
            backgroundColor: "#050000",
          }}
        >
          <div style={{ height: 1 }}></div>
          <div className="background-parent-container" style={{}}>
            <div className="intro-container">
              <p
                className="intro-text-one"
                style={{
                  lineHeight: "1.2",
                  // fontFamily: "Montserrat",
                  // fontFamily: "operetta-8",
                  fontFamily: "operetta-12",
                  // fontFamily: "operetta-18",
                }}
              >
                <div
                  // style={{marginBottom:'-10px'}}
                  style={
                    {
                      // marginBottom: `${matches ? "4px" : "0px"}`,
                    }
                  }
                  // style={{ marginLeft: "20px" }}
                >
                  Sebastian <br />
                </div>
                <div
                  className="intro-text-one-pt2"
                  // onClick={executeScroll_about}
                  style={{ textShadow: "3px 3px 3px rgba(0, 0, 0, 0.4)" }}
                >
                  Gerhardt
                </div>
              </p>

              <p
                className="intro-text-two shadowedText"
                style={{
                  textShadow: "2px 3px 10px rgba(0, 0, 0, 0.9)",
                  fontFamily: "Gilroy",
                  fontSize: "1.3rem",
                }}
              >
                Magic. Mindreading. Mixology. <br />
                <div className="intro-text-two-pt2">Crafted by magic.</div>
              </p>
            </div>
          </div>

          {matches2 ? (
            <div></div>
          ) : (
            <div style={{ maxWidth: "100%", whiteSpace: "nowrap" }}>
              <img
                alt="LG Logo"
                src={LGgrey}
                className={classes.LGgrey}
                // className="section-images-bio"
                // className="section-images-long"
                style={{ width: "18vh" }}
              ></img>
              <img
                alt="Lockheed Logo"
                src={lockheedGrey}
                className={classes.lockheedGrey}
                // className="section-images-bio"
                // className="section-images-long"
                style={{ width: "39vh" }}
              ></img>
              <img
                alt="Orlando Magic Logo"
                src={OrlandoMagicGrey}
                className={classes.OrlandoMagicGrey}
                style={{
                  width: "18vh",
                }}
              ></img>
              <img
                alt="SunTrust Logo"
                src={SunTrustGrey}
                className={classes.SunTrustGrey}
                style={{ width: "23vh" }}
              ></img>
              <img
                alt="WellsFargo Logo"
                src={WellsFargoGrey}
                className={classes.WellsFargoGrey}
                style={{ width: "18vh" }}
              ></img>
            </div>
          )}

          <Grid
            container
            className="contentContainer"
            style={{
              backgroundColor: "#FFFFFF",
              // height: "200px"
              // paddingTop: "200px",
            }}
          >
            <Grid xs={12}>
              <br />
              <div className="section-headers">
                Engage and Delight your Guests
              </div>
              <br />

              <div
                className="section-content-2"
                id="mainText"
                style={{ textAlign: "justify" }}
              >
                Are you looking for top shelf entertainment for your next event
                and only the best will do?
                <p style={{ textIndent: "50px" }}>
                  ... Then professional magician Sebastian Gerhardt is your
                  go-to entertainment solution. Sebastian has delighted
                  audiences around the country for over a decade with
                  performances for the Orlando Magic NBA team, Rifle Paper Co.,
                  Orlando Style Magazine, and SunTrust bank. Sebastian is ready
                  to make your private party or corporate event a most memorable
                  experience for you and your guests. Sebastian currently lives in Orlando, Florida where he mesmerizes audiences of all ages bringing magic to both the young and young at heart and is now available for corporate and private events.
                </p>
              </div>
            </Grid>
          </Grid>

          <Fade left delay={0} duration={1500}>
            <div
              className="section-headers"
              style={{
                color: "white",
                marginTop: "10px",
                marginBottom: `${matches ? "25px" : "10px"}`,
              }}
            >
              TV Appearances
            </div>
          </Fade>
          <Grid container ref={mediaRef}>
            {/* <br /> */}
            <Grid
              xs={12}
              md={6}
              style={{ marginBottom: `${matches ? "30px" : "0px"}` }}
            >
              {/* <Iframe
                url="http://www.youtube.com/embed/xDMP3i36naA"
                position="absolute"
                width="100%"
                id="myId"
                className="myClassname"
                height="100%"
                styles={{ height: "25px" }}
              /> */}
              <Iframe
                url="https://player.vimeo.com/video/806071850?h=b009146e9e"
                // width="100%"
                // height="100%"

                // width="min(450px, 100%)"
                // height="auto"
                width={matches2 ? "75%" : "450px"}
                // width="450px"
                // height="450px"
                height={matches ? "80%" : "450px"}
                styles={
                  {
                    // minHeight: "300px",
                    // height: "100%",
                    // width: "100%",
                    // maxWidth: "65%",
                  }
                }
                id="myId"
                className="myClassname"
                display="initial"
                position="relative"
                frameBorder="0"
              />
            </Grid>
            <Grid xs={12} md={6} style={{ marginBottom: `30px` }}>
              <Iframe
                url="https://player.vimeo.com/video/587184092?h=5fe2f6a3db"
                // width="450px"
                width={matches2 ? "75%" : "450px"}
                height={matches ? "80%" : "450px"}
                // height="450px"
                id="myId"
                className="myClassname"
                display="initial"
                position="relative"
                frameBorder="0"
              />
            </Grid>
          </Grid>

          <Grid
            container
            className="contentContainer"
            // spacing={1}
            style={{
              backgroundColor: "#F1F1F8",
              // height: "200px"
              // paddingTop: "200px",
            }}
          >
            <Grid
              xs={12}
              md={4}
              style={{
                // backgroundColor: "red",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{}}>
                {/* <div> */}
                <div style={{ height: matches ? "40px" : null }}></div>
                <Fade up delay={0} duration={1400}>
                  <img
                    alt="Sebastian in a suit. Dramatic lighting from the side."
                    src={officialBioImage}
                    className="section-images-long  shadowed"
                  ></img>
                </Fade>
                {/* </div> */}

                {/* <div
              className="section-images"
              style={{
                backgroundImage: `url(${blackImage})`,
              }}
            ></div> */}
              </div>
            </Grid>

            <Grid
              xs={12}
              md={8}
              style={{
                // backgroundColor: "purple",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={
                  {
                    // backgroundColor: "grey",
                    // display: "flex",
                    // justifyContent: "center",
                    // alignItems: "center",
                  }
                }
              >
                {/* <br /> */}
                <div style={{ overflow: "hidden" }}>
                  <div ref={aboutRef}>
                    <Fade right delay={0} duration={1500}>
                      <div
                        className="section-headers"
                        // style={{}}
                        id="official-bio-text"
                        style={{ marginTop: `${matches2 ? "20px" : "0px"}` }}
                      >
                        About Sebastian
                      </div>
                    </Fade>
                  </div>
                  <br />
                  <Fade right delay={150} duration={1800}>
                    <div
                      className="section-content"
                      id="mainText"
                      style={{
                        textAlign: "justify",
                        // paddingRight: "90px"
                      }}
                    >
                      Born in Oslo Norway, Sebastian delighted his friends and
                      family with magic at the age of 7. Stemming from his
                      Norwegian roots, he was inspired by the personal
                      connection forged through sharing Close-Up magic. As an
                      Official Magician of the Orlando Magic, he’s also an
                      International Brotherhood of Magician’s award winning
                      performer recognized in MagicSeen and MAGIC Magazine.
                      After his battle with Brain Cancer in 2018, he now brings
                      his talents and message: The transformative impact of
                      miracles. He currently resides in Central Florida, ready to amaze and <span class="nobr">astonish at your next event.</span>
                    </div>
                  </Fade>
                </div>
              </div>
            </Grid>
          </Grid>

          {/* <div style={{ height: 300 }}></div> */}
          {/* <div style={{ height: 50 }}></div> */}
          {/* <img src={logo} className="App-logo" alt="logo" /> */}
          {/* <p>We gon fill this out.</p> */}
          <Grid
            container
            className="contentContainer"
            style={{ backgroundColor: "#FFFFFF" }}
          >
            <Grid
              xs={12}
              md={7}
              style={{
                // backgroundColor: "grey",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  overflow: "hidden",
                  // backgroundColor: "purple"
                }}
              >
                <Fade left delay={0} duration={1500}>
                  <div className="section-headers">
                    Close Up Interactive Magic
                  </div>
                </Fade>

                <br />
                <Fade left delay={100} duration={1800}>
                  <div
                    className="section-content"
                    id="mainText"
                    style={{ textAlign: "justify" }}
                  >
                    Close up Interactive Magic is the ideal way to break with
                    convention and entertain at your next event. Sebastian
                    greets your guests, and they get to experience a private
                    show where the magic happens right in their own hands. This
                    is an exceptional way to welcome everyone in while keeping
                    the energy high.
                  </div>
                </Fade>
              </div>
            </Grid>

            <Grid
              xs={12}
              md={5}
              style={{
                // backgroundColor: "red",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* Image B */}
              {/* <div
            className="section-images"
            style={{
              backgroundImage: `url(${blackImage})`,
            }}
          ></div> */}
              <Fade up delay={0} duration={1400}>
                <img
                  alt="Sebastian performing close-up interactive magic."
                  src={closeUpInteractiveImage}
                  // className="section-images-closeUp"
                  className="section-images shadowed2"
                ></img>
              </Fade>
            </Grid>
          </Grid>
          {/* <div style={{ height: 300 }}></div> */}
          {/* <div style={{ height: 50 }}></div> */}
          {/* <img src={logo} className="App-logo" alt="logo" /> */}
          {/* <p>We gon fill this out.</p> */}
          <Grid
            container
            className="contentContainer"
            style={{ backgroundColor: "#F1F1F8" }}
          >
            <Grid
              xs={12}
              md={5}
              className={classes.groupImage}
              style={{
                // backgroundColor: "red",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              //  ={{ xs: 2, lg: 1 }}
            >
              <Fade up delay={0} duration={1400}>
                <div
                  className="imagefix"
                  style={{ marginBottom: matches2 ? "40px" : "0px" }}
                >
                  <img
                    alt="Sebastian performing group magic show."
                    // id="image-fix"
                    src={groupMagicShowImage}
                    //  ="section-images-groupMagic"
                    className="section-images shadowed2"
                    // style={{ marginBottom: "8px" }}
                  ></img>
                </div>
              </Fade>
            </Grid>

            <Grid
              xs={12}
              md={7}
              className={classes.groupText}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ overflow: "hidden" }}>
                <Fade right delay={0} duration={1500}>
                  <div className="section-headers">A Magical Group Show</div>
                </Fade>
                <br />
                <Fade right delay={100} duration={1800}>
                  <div
                    className="section-content"
                    id="mainText"
                    style={{ textAlign: "justify" }}
                  >
                    The group magic show captures the attention of all your
                    guests. It’s a 20-60 minute highly interactive show for
                    everyone to enjoy together. This is Sebastian’s most
                    successful spotlight program and is something guests will be
                    talking about for years to come.
                  </div>
                </Fade>
              </div>
            </Grid>
          </Grid>

          {/* <Grid
            container
            className="contentContainer"
            style={{ backgroundColor: "#FFFFFF" }}
          >
            <Grid
              xs={12}
              md={7}
              style={{
                // backgroundColor: "purple",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ overflow: "hidden" }}>
                <Fade left delay={0} duration={1500}>
                  <div className="section-headers">
                    Crafted Magic Experience
                  </div>
                </Fade>
                <br />
                <Fade left delay={100} duration={1800}>
                  <div
                    className="section-content"
                    id="mainText"
                    style={{ textAlign: "justify" }}
                  >
                    The Crafted Magic Experience combines craft cocktail mixing
                    with mind blowing magic in a show that will ensure everyone
                    at your event has a magical memory. Sebastian performs
                    behind the bar, making drinks appear, reading minds, and
                    taking care of your beverage serving needs.
                  </div>
                </Fade>
              </div>
            </Grid>

            <Grid
              xs={12}
              md={5}
              style={{
                // backgroundColor: "red",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Fade up delay={0} duration={1400}>
                <img src={craftedMagicImage} className="section-images"></img>
              </Fade>
            </Grid>
          </Grid> */}
          {/* <Eventbrite/> */}
          <div
            style={{ width: "90%", marginInline: "auto", padding: "20px 0px", display: 'none' }}
          >
            <Fade right delay={0} duration={1500}>
              <div className="section-headers" style={{color:'white', marginBottom:'34px'}}>Upcoming Shows</div>
            </Fade>
            <iframe
              width="100%"
              height="550"
              src="https://www.eventbrite.com/e/the-other-side-sebastian-the-mentalist-tickets-419233698277?aff=efbneb"
            ></iframe>
          </div>
        </div>

        <div className="App-footer">
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid xs={12} md={6}>
              <div
                style={
                  {
                    // marginLeft: `${!matches ? "0px" : "-80px"}`,
                    // marginBottom: "-20px",
                  }
                }
              >
                <img style={{width:'40%'}} className="footer-logo" src={logo} alt="`S` logo" />
              </div>
            </Grid>
            <Grid xs={12} md={6}>
              <br />
              <p
                style={{
                  color: "white",
                  fontSize: "1.4rem",
                  paddingTop: "10px",

                  fontFamily: "operetta-8",
                  lineHeight: "1.3em",
                }}
              >
                FOR BOOKINGS <br /> &#38; <br /> MORE INFORMATION:
              </p>
              <p
                style={{
                  color: "white",
                  fontSize: "1.4rem",
                  fontFamily: "operetta-8",
                  // fontFamilty: "Inter",
                  marginTop: "-7px",
                }}
              >
                (470) 202-8961
              </p>
              <p
                style={{
                  color: "white",
                  fontSize: "1.3rem",
                  marginTop: "-3px",
                }}
              >
                info@sebastianthemagician.com
              </p>

              <div style={{ maxWidth: "100%" }}>
                <img
                  alt="Instagram logo which takes to Sebastians page."
                  src={InstagramSVG}
                  onClick={() =>
                    openInNewTab(
                      "https://www.instagram.com/sebastian.gerhardt/"
                    )
                  }
                  style={{
                    width: "7vh",
                    paddingLeft: "10px",
                    paddingRight: "15px",
                    paddingTop: "10px",
                    cursor: "pointer",
                  }}
                ></img>
                <img
                  alt="Facebook icon that takes to Sebastians page."
                  src={FacebookSVG}
                  onClick={() =>
                    openInNewTab(
                      "https://www.facebook.com/sebastianthemagician"
                    )
                  }
                  style={{
                    width: "7vh",
                    paddingLeft: "15px",
                    paddingTop: "10px",
                    cursor: "pointer",
                  }}
                ></img>
              </div>
              <br />
              <br />
              <br />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
