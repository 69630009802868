// // Import the functions you need from the SDKs you need

// import { initializeApp } from "firebase/app";

// import { getAnalytics } from "firebase/analytics";

// // TODO: Add SDKs for Firebase products that you want to use

// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration

// // For Firebase JS SDK v7.20.0 and later, measurementId is optional

// const firebaseConfig = {
//   apiKey: "AIzaSyCFemSTRjV0idsBzXvYHXthZHS9456sosg",

//   authDomain: "craftedwebsite.firebaseapp.com",

//   databaseURL: "https://craftedwebsite-default-rtdb.firebaseio.com",

//   projectId: "craftedwebsite",

//   storageBucket: "craftedwebsite.appspot.com",

//   messagingSenderId: "1075403546571",

//   appId: "1:1075403546571:web:e2de9f2431711b35d5604e",

//   measurementId: "G-DN1RRND4M4",
// };

// // Initialize Firebase

// const firebase = initializeApp(firebaseConfig);

// const analytics = getAnalytics(firebase);

// export default firebase;

// import firebase from "firebase";
// require("firebase/firestore");
// require("firebase/app-check");

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// import { getAuth, signInAnonymously } from "firebase/auth";
const {
  initializeAppCheck,
  ReCaptchaV3Provider,
} = require("firebase/app-check");

const config = {
  apiKey: "AIzaSyCFemSTRjV0idsBzXvYHXthZHS9456sosg",

  authDomain: "craftedwebsite.firebaseapp.com",

  databaseURL: "https://craftedwebsite-default-rtdb.firebaseio.com",

  projectId: "craftedwebsite",

  storageBucket: "craftedwebsite.appspot.com",

  messagingSenderId: "1075403546571",

  appId: "1:1075403546571:web:e2de9f2431711b35d5604e",

  measurementId: "G-DN1RRND4M4",
};
const app = initializeApp(config);
const db = getFirestore();

// firebase.initializeApp(config);

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LdHF_wcAAAAAMKwPG8FonOiI27dYmyqV8dOqYZI"),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true,
});

export default db;
// export default firebase;
