import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import AddressForm from "./AddressForm";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const steps = ["Personal Details"];

export default function Form() {
  const classes = useStyles();

  const [activeStep, setActiveStep] = React.useState(0);
  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };
  return (
    <div className="background-parent-container-contact" style={{}}>
      {" "}
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid xs={11} md={6} style={{ paddingTop: "200px" }}>
          <div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            {/* <br /> */}
            <Paper
              className={classes.paper}
              style={{ backgroundColor: "rgba(255,255,255,0.8)" }}
              elevation={3}
            >
              <React.Fragment>
                {activeStep === steps.length ? (
                  <React.Fragment>
                    <Typography variant="h5" gutterBottom>
                      {/* Thank you for your order. */}
                      Message Sent!
                    </Typography>
                    <Typography variant="subtitle1">
                      <br></br>
                      <br></br>
                      <br></br>
                      <br></br>
                      <br></br>
                      <br></br>
                      <br></br>
                      <br></br> <br></br>
                      <br></br>
                      {/* Your order number is #2001539. We have emailed your order confirmation, and will
          send you an update when your order has shipped. */}
                    </Typography>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <AddressForm></AddressForm>
                    {/* {getStepContent(activeStep)} */}
                    <div className={classes.buttons}>
                      {activeStep !== 0 && (
                        <Button onClick={handleBack} className={classes.button}>
                          Back
                        </Button>
                      )}
                    </div>
                  </React.Fragment>
                )}
              </React.Fragment>
            </Paper>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
