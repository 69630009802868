import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
// import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
// import axios from './axios-stories';
// import axios from "axios";
import Button from "@material-ui/core/Button";
import db from "./../../Firestore";

// const getTimeEpoch = () => {
//   return new Date().getTime().toString();
// };

const useInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    setValue,
    reset: () => setValue(""),
    bind: {
      value,
      onChange: (event) => {
        setValue(event.target.value);
      },
    },
  };
};

export default function AddressForm() {
  // const [name, setName] = useState("");
  // const [name, setName] = useState("");
  // const [name, setName] = useState("");
  // const [name, setName] = useState("");

  const {
    value: fullName,
    bind: bindFullName,
    reset: resetFullName,
  } = useInput("");
  const { value: email, bind: bindEmail, reset: resetEmail } = useInput("");
  const { value: budget, bind: bindBudget, reset: resetBudget } = useInput("");
  const {
    value: organization,
    bind: bindOrganization,
    reset: resetOrganization,
  } = useInput("");
  const { value: number, bind: bindNumber, reset: resetNumber } = useInput("");
  const { value: date, bind: bindDate, reset: resetDate } = useInput("");

  const {
    value: messageZ,
    bind: bindMessage,
    reset: resetMessage,
  } = useInput("");
  // const { value: reason, bind: bindReason, reset: resetLReason } = useInput("");
  // const { value: source, bind: bindSource, reset: resetSource } = useInput("");

  const handleSubmit = (evt) => {
    evt.preventDefault();

    // const message = {
    //   name: fullName,
    //   email: email,
    //   number: number,
    //   organization: organization,
    //   event_date: date,
    //   budget: budget,
    //   message: messageZ,
    //   submission_date: new Date().toString(),
    // };
    const message = {
      // to: "info@sebastianthemagician.com",
      to: "sebastian@pinnaclespades.com",
      // to: "danielcarvajal1295@gmail.com",

      message: {
        subject: `Contact Form Submission from ${fullName}`,
        text: `

          name: ${fullName},
          email: ${email},
          number: ${number},
          organization: ${organization},
          event_date: ${date},
          budget: ${budget},
          message: ${messageZ},

          `,
        // },
        html: `
        <ul style="font-size:20px">
      <li>Name: ${fullName}</li>
      <li>Email: ${email}</li>
      <li>Number: ${number}</li>
      <li>Organization: ${organization}</li>
      <li>Event Date: ${date}</li>
      <li>Budget: ${budget}</li>
      <li>Message: ${messageZ}</li>
          </ul>
      `,
      },
    };

    // axios
    //   .post(
    //     "https://craftedwebsite-default-rtdb.firebaseio.com/messages.json",

    //     { message }
    //   )
    //   .then((res) => {
    //     // console.log(res);
    //     // console.log(res.data);
    //     // window.location = "/retrieve" //This line of code will redirect you once the submission is succeed
    //   });

    // const db = firebase.firestore();
    db.settings({
      timestampsInSnapshots: true,
    });
    // const userRef = db.collection("posts").add({
    //   // to: ["sebastian@pinnaclespades.com"],
    //   message,
    // });

    const emailRef = db.collection("email_collection").add(
      // to: ["sebastian@pinnaclespades.com"],
      message
    );

    // alert(`Name = ${fullName},  Email = ${email}, Reason = ${reason}, Source = ${source}`, );
    resetFullName();
    resetEmail();
    resetNumber();

    resetOrganization();
    resetDate();
    resetBudget();
    resetMessage();
    // resetLReason();
    // resetSource();
  };

  return (
    <React.Fragment>
      <br></br>

      {/* <form onSubmit={handleSubmit}>
      <label>
        Full Name:
        <input type="text" {...bindFullName} />
      </label>
      <label>
        Email:
        <input type="text" {...bindEmail} />
      </label>
      <input type="submit" value="Submit" />
    </form> */}

      <form onSubmit={handleSubmit}>
        {/* <Typography variant="h7" gutterBottom style={{ fontFamily: "Archivo" }}>
          Please use this form to submit questions, suggest FR timeline events,
          request collaboration, schedule me to speak/moderate your event, and
          provide feedback
        </Typography> */}
        {/* <br></br>
        <br></br> */}
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <TextField
              {...bindFullName}
              required
              id="firstName"
              name="firstName"
              label="Your Name"
              fullWidth
              autoComplete="fname"
              // style={{ fontFamily: "Gilroy" }}
              // style={{ color: "white" }}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            name="lastName"
            label="Last name"
            fullWidth
            autoComplete="lname"
          />
        </Grid> */}
          <Grid item xs={12}>
            <TextField
              {...bindEmail}
              required
              id="email"
              name="email"
              label="Email"
              fullWidth
              autoComplete="email"
              // style={{ fontFamily: "Gilroy" }}

              // autoComplete="billing address-line1"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              {...bindNumber}
              required
              id="phone"
              name="phone"
              label="Phone Number"
              fullWidth
              autoComplete="phone"

              // autoComplete="billing address-line1"
            />
          </Grid>
          {/* <Grid item xs={12}>
          <TextField
            id="address2"
            name="address2"
            label="Address line 2"
            fullWidth
            autoComplete="billing address-line2"
          />
        </Grid> */}
          <Grid item xs={12}>
            <TextField
              {...bindOrganization}
              required
              id="organization"
              name="organization"
              label="Organization"
              fullWidth
              autoComplete="organization"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              {...bindDate}
              // required
              id="organization"
              name="organization"
              label="Date of Event"
              fullWidth
              autoComplete="organization"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              {...bindBudget}
              // required
              id="Budget"
              name="Budget"
              label="Budget"
              fullWidth
              autoComplete="off"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              {...bindMessage}
              // required
              id="message"
              name="message"
              label="Your Message"
              fullWidth
              autoComplete="message"
              multiline
              // rowsMin={4}
              rows={3}
              rowsMax={Infinity}
              // autoComplete="billing address-line1"
            />
          </Grid>

          <Grid item xs={12}>
            {/* <FormControlLabel
            control={<Checkbox color="secondary" name="saveAddress" value="yes" />}
            label="Job Inquiry?"
          /> */}
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              // className={classes.button}
              // disabled
            >
              {"Submit"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
}
